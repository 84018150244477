import { Backdrop, Paper, useTheme } from "@mui/material";
import { useEffect, useMemo, useRef, useState } from "react";
import useAuth from "src/hooks/useAuth";
import { getSession } from "src/utils/jwt";

const files = {
  1280_800: "/video/1280_800.mp4",
  1920_1080: "/video/1920_1080.mp4",
  328_926: "/video/328_926.mp4",
  360_800: "/video/360_800.mp4",
  390_844: "/video/390_844.mp4",
  393_873: "/video/393_873.mp4",
  414_896: "/video/414_896.mp4",
  768_1024: "/video/768_1024.mp4",
  800_1180: "/video/800_1180.mp4",
  810_1080: "/video/810_1080.mp4",
  962_601: "/video/962_601.mp4",
};

const getVideoURI = () => {
  const currentWidthHeight = parseInt(
    `${window.innerWidth}${window.innerHeight}`
  );
  const screenWidthSize = Object.keys(files);

  let closest = { difference: Infinity, key: null };
  screenWidthSize.forEach((item) => {
    const difference = Math.abs(parseInt(item) - currentWidthHeight);
    if (difference < closest.difference) {
      closest = { difference, key: item };
    }
  });

  // Return the closest matching video or `null` if no match is found
  return files[closest.key] || null;
};

const ButterflyLoader = ({ children }) => {
  const isImpersonate = localStorage.getItem("isImpersonate") !== "true";
  const theme = useTheme();
  const isDark = theme.palette.mode === "dark";
  const isLoggedIn = getSession();
  const [showLoader, setIsLoaded] = useState(
    () => localStorage.getItem("show_loader") !== "false"
  );
  const ref = useRef(null);

  useEffect(() => {
    if (isLoggedIn && ref.current) {
      ref.current.play();
      const timeout = setTimeout(() => {
        localStorage.setItem("show_loader", "false");
        setIsLoaded(false);
      }, 6500);

      return () => {
        clearTimeout(timeout);
        ref.current.pause();
      };
    }
  }, [isLoggedIn]);

  const videoUrl = useMemo(() => getVideoURI(), []);

  const { isAdmin } = useAuth();

  return showLoader && isImpersonate ? (
    <Backdrop
      sx={{
        color: "#fff",
        zIndex: theme.zIndex.drawer + 1,
        backgroundColor: isDark ? "#212B36" : "#fff",
      }}
      open={showLoader}
    >
      <Paper
        sx={{
          position: "relative",
          width: "100%",
          height: "100%",
          borderRadius: 0,
          overflow: "hidden",
        }}
      >
        <video
          ref={ref}
          autoPlay
          muted
          playsInline
          controls={false}
          preload="auto"
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            objectFit: videoUrl ? "cover" : "fill", // Stretch the video if no resolution matches
            backgroundColor: videoUrl ? "transparent" : "#000", // Add background for better contrast
          }}
        >
          {videoUrl ? (
            <source src={videoUrl} type="video/mp4" />
          ) : (
            <source src="/video/butterfly.mp4" type="video/mp4" />
          )}
          Your browser does not support the video tag.
        </video>
      </Paper>
    </Backdrop>
  ) : (
    <>{children}</>
  );
};

export default ButterflyLoader;
